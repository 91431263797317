import React from "react"
import PropTypes from "prop-types"
import { Button, Card } from "antd"
import "./CardNews.less"
import { Link } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"

function CardNews(props) {
  const { t } = useI18next()
  return (
    <Card
      bordered={false}
      className="card-news"
      cover={<img alt={props.title} src={props.image} />}
    >
      <div className="card-meta">
        {props.category || props.date ? (
          <p style={{ marginBottom: 0, fontWeight: "600" }}>
            <span className="category">{props.category}</span> | {props.date}
          </p>
        ) : null}
        <h3 className="card-title">{props.title}</h3>
        <Button type="link">
          <Link to={props.url}>{t("Selengkapnya")}</Link>
        </Button>
      </div>
    </Card>
  )
}

CardNews.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  category: PropTypes.string,
  url: PropTypes.string,
}

CardNews.defaultProps = {
  url: "/informasi/berita/single",
}

export default CardNews
