import React from "react"
import PropTypes from "prop-types"
import { PlayCircleFilled } from "@ant-design/icons"
import { Modal, Button } from "antd"
import YouTube from "react-youtube"

import "./SectionWithVideo.less"
class SectionWithVideo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
    }
    // create a ref to store the textInput DOM element
    this.videoIframe = React.createRef()
  }

  showModal = () => {
    this.setState({ visible: true })
  }

  handleCancel = () => {
    this.setState({ visible: false })
    this.videoIframe.current.internalPlayer.pauseVideo()
  }

  render() {
    const { visible } = this.state

    const opts = {
      height: "390",
      width: "100%",
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
        rel: 0,
        modestbranding: 0,
        showinfo: 0,
      },
    }

    return (
      <div className="section-with-video" style={{ overflow: "hidden" }}>
        <div className="section-video-bg">
          <div
            className="section-video-bg-image"
            style={{ backgroundImage: `url(${this.props.videoBg})` }}
          >
            <Button
              onClick={this.showModal}
              icon={<PlayCircleFilled />}
              className="btn-outline-white"
              size="large"
            >
              {this.props.playBtnText}
            </Button>

            <Modal
              className="video-modal"
              visible={visible}
              onCancel={this.handleCancel}
              footer={false}
            >
              <YouTube
                ref={this.videoIframe}
                videoId={this.props.videoId}
                opts={opts}
              />
            </Modal>
          </div>
        </div>

        <div className="section section-video-content">
          {this.props.children}
        </div>
      </div>
    )
  }
}

SectionWithVideo.propTypes = {
  children: PropTypes.node,
  videoBg: PropTypes.string,
  videoId: PropTypes.string,
  playBtnText: PropTypes.string,
}

export default SectionWithVideo
