import React, { Component } from "react"
import PropTypes from "prop-types"
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { Button } from "antd"
import Container from "../Container"
import { Row, Col } from "antd"
import _orderBy from "lodash/orderBy"

import "./MainSlider.less"

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay])

export class MainSlider extends Component {
  render() {
    const orderedSlider = _orderBy(this.props.sliderContent, ["order"], "asc")

    return (
      <Swiper
        autoplay
        slidesPerView={1}
        pagination={{ clickable: true }}
        className="main-slider-container"
      >
        {orderedSlider.length > 0 &&
          orderedSlider.map(function (item, i) {
            return (
              <SwiperSlide key={i}>
                <div className="main-slider">
                  <div
                    className="main-slider-image hide-mobile"
                    style={{ backgroundImage: `url(${item.banner_image})` }}
                  />
                  <div
                    className="main-slider-image-mobile show-mobile"
                    style={{
                      backgroundImage: `url(${item.banner_image_mobile})`,
                    }}
                  />
                  <div className="main-slider-caption">
                    <Container>
                      <Row>
                        <Col md={12}>
                          <h2 style={{ color: item.text_color }}>
                            {item.title}
                          </h2>
                          <Button href={item.button_url} size="large">
                            {item.button_text}
                          </Button>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </div>
              </SwiperSlide>
            )
          })}
      </Swiper>
    )
  }
}

MainSlider.propTypes = {
  sliderContent: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      imageMobile: PropTypes.string,
      caption: PropTypes.string,
      captionColor: PropTypes.string,
      buttonText: PropTypes.string,
      buttonURL: PropTypes.string,
    })
  ),
}

MainSlider.defaultProps = {
  sliderContent: PropTypes.shape({
    captionColor: "#000",
    buttonText: "Lebih Lanjut",
  }),
}

export default MainSlider
